<template>
    <div class="Brand" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.goods.brand.open')"
            >
                查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.goods.brand.create')">
                新建
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.goods.brand.export')">
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column prop="code" label="编码" width="180" />
                <el-table-column prop="name" label="名称" width="180" />
                <el-table-column prop="creator" label="创建人" width="180" />
                <el-table-column prop="createTime" label="创建时间" width="140" />
                <el-table-column label="操作" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.goods.brand.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.goods.brand.delete')"
                            >删除
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="rowHistory(scope.row.code)"
                            v-if="hasPrivilege('menu.goods.brand.open')"
                            >历史
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="变更历史" :visible.sync="dialogTableVisible">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="historyData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" align="center" fixed="left" />
                <el-table-column property="updater" label="变更人" width="150" />
                <el-table-column property="updateTime" label="变更日期" width="150" />
                <el-table-column property="name" label="名称" width="fit" />
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';

export default {
    name: 'Brand',
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            dialogTableVisible: false,
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            histPage: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            nowSelectBrandCode: '',
            loading: false,
            historyData: [],
            tableData: [],
            url: {
                historyPage: '/goods/brand/change/page',
                page: '/goods/brand/page',
                delete: '/goods/brand/delete',
                export: '/goods/brand/export',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '品牌', this.url.export, this.form, codes);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.goods.brand.create', ['商品管理', '品牌管理', '新建品牌']);
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.goods.brand.edit', ['商品管理', '品牌管理', '编辑品牌'], {
                form: row,
                code: row.code,
            });
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.goods.brand.detail', ['商品管理', '品牌管理', '品牌详情'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(code) {
            UrlUtils.DeleteRemote(this, this.url.delete, code);
        },
        rowHistory(code) {
            const _this = this;
            _this.dialogTableVisible = true;
            _this.nowSelectBrandCode = code;
            const _params = {
                params: { bizCode: code },
            };
            Util.queryTable(_this, _this.url.historyPage, _params, (data) => {
                _this.historyData = data.data;
            });
        },
        handleHistorySizeChange(size) {
            this.histPage.page = 1;
            this.histPage.limit = size;
            this.rowHistory(this.nowSelectBrandCode);
        },
        handleHistoryCurrentChange(page) {
            this.histPage.page = page;
            this.rowHistory(this.nowSelectBrandCode);
        },
        handleHistoryPrevClick(page) {
            this.histPage.page = page;
            this.rowHistory(this.nowSelectBrandCode);
        },
        handleHistoryNextClick(page) {
            this.histPage.page = page;
            this.rowHistory(this.nowSelectBrandCode);
        },
    },
};
</script>

<style scoped>
.Brand .el-form-item {
    margin-bottom: 0;
}
</style>
